<template>
  <a-layout-header
    class="header-wrapper"
    style="background-color:rgb(4, 21, 40);"
    :class="collapsed ? 'menu-fold-header' : 'menu-unfold-header'"
  >

    <!--    <a-icon-->
    <!--      class="trigger"-->
    <!--      :type="collapsed ? 'menu-unfold' : 'menu-fold'"-->
    <!--      @click="handleCollapsedChange"-->
    <!--    />-->

    <div class="search-wrapper" v-if="this.$store.getters.userRole !== 'open_user'">
      <a-spin :spinning="globalSearch.loading">
        <a-input-search
          placeholder="请输入 ICCID 或 SIMID 或 手机号 或 IMSI 或 短号"
          size="large"
          :loading="globalSearch.loading"
          v-model.trim="globalSearch.key"
          @search="handleGlobalSearchCard"
        />
      </a-spin>
    </div>

    <div class="search-wrapper" v-if="this.$store.getters.userRole === 'open_user'">
      <a-spin :spinning="globalSearch.loading">&nbsp;</a-spin>
    </div>

    <div class="right-items-wrapper">
      <div class="links">
        <ul>
          <!--          <li>-->
          <!--            <a-badge-->
          <!--              :count="unreadNotificationCount"-->
          <!--              :overflow-count="99"-->
          <!--              :number-style="{-->
          <!--                backgroundColor: this.$primaryColor,-->
          <!--                fontSize: '1px',-->
          <!--                boxShadow: 'rgb(255 255 255) 0px 0px 0px 1px inset',-->
          <!--              }"-->
          <!--            />-->

          <!--            <a-tooltip>-->
          <!--              <template slot="title">-->
          <!--                通知消息-->
          <!--              </template>-->

          <!--              <router-link to="/notifications">-->
          <!--                <a-icon type="alert" />-->
          <!--              </router-link>-->
          <!--            </a-tooltip>-->
          <!--          </li>-->

          <li v-if="this.$store.getters.userRole !== 'open_user'">
            <a-tooltip>
              <template slot="title">
                导出管理
              </template>
              <router-link to="/data_export_tasks">
                <a-icon type="download" />
              </router-link>
            </a-tooltip>
          </li>

          <li v-if="isSwitchedUser">
            <a-tooltip>
              <template slot="title">
                返回初始账号
              </template>
              <a @click="switchBack">
                <a-icon type="undo" />
              </a>
            </a-tooltip>
          </li>

          <li v-if="this.$store.getters.userRole === 'top_agent'">
            <a-tooltip>
              <template slot="title">
                主页
              </template>
              <a href="#" target="_blank">
                <a-icon type="bank" />
              </a>
            </a-tooltip>
          </li>

          <li v-if="false">
            <a-tooltip>
              <template slot="title">
                API
              </template>
              <a href="#" target="_blank">
                <a-icon type="api" />
              </a>
            </a-tooltip>
          </li>
        </ul>
      </div>

      <div>
        <a-dropdown placement="bottomRight">
          <a-space class="iotplt-cursor-pointer">
            <a-avatar icon="idcard" />
            <!--            <a-icon type="idcard" />-->
            <div class="user-full-name" style="color: #ffffff">
              {{ this.$store.getters.userFullName }}
            </div>
          </a-space>
          <a-menu slot="overlay">
            <a-menu-item
              key="info"
              v-if="this.$store.getters.userRole !== 'open_user'"
            >
              <router-link :to="{ name: 'user_info', params: { id: this.$store.getters.userId }}">
                <a-space>
                  <a-icon type="idcard" />个人资料
                </a-space>
              </router-link>
            </a-menu-item>
            <a-menu-item
              key="logout"
              @click="handleLogout"
            >
              <a-space>
                <a-icon type="logout" />
                退出
              </a-space>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>

      <show-notification
        v-if="isShowNotificationModal"
        :visible.sync="isShowNotificationModal"
        :id="showingNotificationId"
        @alreadyRead="alreadyReadAutoAlertNotification"
      />

      <component
        :key="globalSearch.componentKey"
        :is="globalSearch.currentComponent"
        :id="globalSearch.recordId"
        :visible.sync="globalSearch.visible"
      />
    </div>

    <!-- 同意平台服务协议  -->
    <agreement-agree
      v-if="this.$store.getters.isShowAgreement && !isSwitchedUser"
      :visible.sync="this.$store.getters.isShowAgreement"
    />
  </a-layout-header>
</template>

<script>
import { globalSearch } from '@/api/search'
import { getOriginalUserUid, removeOriginalUserUid } from '@/utils/token'
import { switchBack } from '@/api/switch_user'
import { setPageTitle } from '@/utils/dom_util'
import { findAlertNotification } from '@/api/notification'
export default {
  name: 'HeaderNavbar',
  components: {
    ShowNotification: () => import('@/views/notifications/Show'),
    ShowSimCard: () => import('@/views/sim_cards/Show'),
    PersonalInfo: () => import('@/views/users/personal_info/index.vue'),
    AgreementAgree: () => import('@/views/agreements/AgreementAgree')
  },
  data() {
    return {
      showingNotificationId: 0, // 显示的通知消息id
      isShowNotificationModal: false, // 是否显示通知消息弹窗
      notificationTimer: null,
      collapsed: false,
      globalSearch: {
        key: '', // 全局搜索的值
        loading: false, // 加载中
        recordId: 0, // 记录id
        visible: false, // 是否显示
        currentComponent: '', // 当前用来显示 record 的组件
        componentKey: '' // 组件key，用来强制刷新组件
      }
    }
  },
  watch: {
    // 设置浏览器 tab 中新消息条数
    unreadNotificationCount() {
      setPageTitle(this.$route)
    }
  },
  computed: {
    isSwitchedUser() {
      return getOriginalUserUid()
    },

    // 未读通知个数
    unreadNotificationCount() {
      return this.$store.getters.unreadNotificationCount
    }
  },
  created() {
    this.loopCheckNotifications()
    this.autoAlertNotification()
  },
  destroyed() {
    clearInterval(this.notificationTimer)
  },
  methods: {
    toApi() {
      this.$router.push({ name: 'api_doc' })
    },

    // 轮询检测新的通知消息
    loopCheckNotifications() {
      this.$pullNotifications()
      this.notificationTimer = setInterval(this.$pullNotifications, 10000)
    },

    // 自动弹出需要展示的通知消息
    autoAlertNotification() {
      if (!this.$store.getters.isNeedCheckAutoAlertNotification) {
        return
      }

      findAlertNotification().then((res) => {
        if (res.code === 0) {
          this.$store.dispatch('SetIsNeedCheckAutoAlertNotification', false)
          this.showingNotificationId = res.data.id
          this.isShowNotificationModal = res.data.is_alert
        }
      })
    },

    // 自动弹出的通知消息已读
    alreadyReadAutoAlertNotification(id) {
      this.$store.dispatch('SetAutoAlertNotificationId', id)
    },

    handleCollapsedChange() {
      this.collapsed = !this.collapsed
      this.$emit('change', this.collapsed)
    },

    // 切回原账号登录
    switchBack() {
      const originalUserUid = getOriginalUserUid()
      if (!originalUserUid) {
        window.location.reload()
        return
      }

      switchBack({ original_user_uid: getOriginalUserUid() }).then((res) => {
        if (res.code === 0) {
          location.href = res.data.redirect_url
        } else {
          removeOriginalUserUid()
        }
      })
    },

    // 全局搜索
    handleGlobalSearchCard() {
      if (!this.globalSearch.key) {
        return
      }

      this.globalSearch.loading = true
      globalSearch(this.globalSearch.key).then(res => {
        if (res.code === 0) {
          this.globalSearch.componentKey = Math.random()
          this.globalSearch.visible = true
          this.globalSearch.recordId = res.data.record_id

          switch (res.data.record_type) {
            case 'sim_card':
              this.globalSearch.currentComponent = 'ShowSimCard'
              break
            default:
              this.globalSearch.currentComponent = ''
          }
        }

        this.globalSearch.loading = false
      })
    },

    handleLogout() {
      return this.$store.dispatch('Logout').then(() => {
        this.$router.push({ name: 'login' })
      })
    }
  }
}
</script>

<style lang="less" scoped>
// 收缩隐藏按钮
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: @primary-color;
  }
}

// 顶部
.header-wrapper {
  padding: 0;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  -webkit-transition: width .2s;
  box-shadow: 0 4px 24px 0 #22292f1a;
  -webkit-box-shadow: 0 4px 24px 0 #22292f1a;

  .search-wrapper {
    width: 500px;
    flex-grow: 1;
    margin-left: 16px;
  }

  .right-items-wrapper {
    padding-right: 20px;
    display: flex;

    .user-full-name {
      overflow: hidden;
      max-width: 200px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical
    }

    .links {
      margin-right: 10px;

      // 小圆点样式
      /deep/ .ant-badge-not-a-wrapper {
        position: absolute;
        right: 0;
        top: 10px;
      }

      ul {
        display: flex;
      }

      li {
        position: relative;
        display: inline-flex;
        width: 50px;
        height: 64px;
        overflow: hidden;

        a {
          display: block;
          width: 100%;
          text-align: center;
          padding: 5px 0;
        }

        i {
          color: #b1b0b0;
          font-size: 25px;
          cursor: pointer;
        }

        &:hover {
          background-color: @primary-color;

          i {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
