<template>
  <div class="iotplt-content-box">
    <a-form
      class="iotplt-compact-form"
      :form="form"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 8 }"
      @submit="handleSubmit"
    >
      <a-form-item label="LOGO">
        <a-checkbox
          :checked="isShowSiteLogo"
          v-decorator="['is_show_site_logo']"
          @change="changeIsShowSiteLogo"
        /> 展示LOGO
      </a-form-item>

      <a-form-item label="自定义LOGO" v-if="!siteLogoUrl">
        <span
          slot="extra"
          class="tip-text"
        >仅支持 .png格式照片，尺寸建议为 200*64</span>
        <upload-image
          accept=".png"
          @change="handleSiteLogoChange"
          v-decorator="['site_logo', {rules: [
            { validator: checkSiteLogo }
          ]
          }]"
        />
      </a-form-item>

      <a-form-item label="自定义LOGO" v-if="siteLogoUrl">
        <img
          :src="siteLogoUrl"
          style="height:64px;padding:5px;"
          alt=""
        >
        <a-button type="primary" style="margin-left:5px;" @click="showUploadSiteLogo">上传</a-button>
      </a-form-item>

      <a-form-item>
        <a-col :span="3" />
        <a-col :span="8">
          <a-button
            type="primary"
            html-type="submit"
            :loading="submitting"
          >提交</a-button>
        </a-col>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { getCustomizeSiteSetting, updateCustomizeSiteSetting } from '@/api/customize_site'
import UploadImage from '@/components/Upload/Image'

export default {
  name: 'CustomizeSite',
  components: { UploadImage },
  data() {
    return {
      data: {},
      loading: true,
      isShowSiteLogo: true, // 是否显示站点logo
      siteLogoUrl: '',
      siteLogo: undefined,
      submitting: false,
      form: this.$form.createForm(this, { name: 'customize_form' })
    }
  },
  computed: {
    agentId() {
      return this.$store.getters.userAgentId
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      getCustomizeSiteSetting(
        Object.assign({ agent_id: this.agentId })
      ).then((res) => {
        if (res.code === 0) {
          this.data = res.data

          this.isShowSiteLogo = res.data.is_show_site_logo
          this.siteLogoUrl = res.data.site_logo_url
        }

        this.form.setFieldsValue({
          is_show_site_logo: this.data.is_show_site_logo
        })

        this.loading = false
      })
    },

    // 改变是否展示logo
    changeIsShowSiteLogo(e) {
      if (e.target.checked) {
        this.isShowSiteLogo = true
      } else {
        this.isShowSiteLogo = false
      }
    },

    // 检查是否上传logo
    checkSiteLogo(rule, value, callback) {
      console.log(this.siteLogo)
      if (this.siteLogo && this.siteLogo[0].status === 'error') {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请上传LOGO')
        return
      }

      callback()
    },

    showUploadSiteLogo() {
      this.siteLogoUrl = ''
    },

    handleSiteLogoChange(fileList) {
      if (Object.keys(fileList).length > 0) {
        this.siteLogo = fileList
      } else {
        this.siteLogo = undefined
      }
    },

    handleSubmit(e) {
      console.log(e)
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      if (this.$lodash.find(this.siteLogo, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            agent_id: this.agentId
          }

          if (this.siteLogo === undefined) {
            delete data.site_logo
          } else {
            data.site_logo = this.siteLogo[0].response
          }

          updateCustomizeSiteSetting(data).then((res) => {
            if (res.code === 0) {
              this.$success({
                title: '提交成功',
                content: (
                  <div>
                    <p>操作成功</p>
                  </div>
                )
              })
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style>

</style>
