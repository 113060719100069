import BasicLayout from '@/layouts/BasicLayout'
import CustomStyleLayout from '@/layouts/CustomStyleLayout'
import RegisteredAgentLayout from '@/views/registered_agent_home/RegisteredAgentLayout.vue'
import RegisteredAgentHome from '@/views/registered_agent_home'
// meta 部分说明
// 如 meta: { title: '用户管理', permission: 'users', menu: 'users' }
// permission 用于权限控制，api 返回的权限列表中有 users 才可以访问
// menu       用于菜单选中高亮，如果不是菜单则可以不传值，值需要和 a-menu-item key="users" 的key值相同
// title      用于浏览器 tab 显示以及面包屑显示
//            - 以 ':' 开头的 title 为动态的值占位符, 用作业务逻辑中的值替换
//              可使用 SetDynamicBreadCrumbsPlaceholder 方法替换占位符的值, 如：
//              this.$store.dispatch('SetDynamicBreadCrumbsPlaceholder', { ':agent_name': '客户1' })
//            - 还提供了 ConcatDynamicBreadCrumbs 方法追加面包屑, 常用于面包屑生成规则不统一，自行生成面包屑

// 异步路由(需要走权限控制)
export const asyncRouter = [
  {
    path: '/balance',
    component: BasicLayout,
    meta: { menu: 'finance' },
    children: [
      {
        path: 'recharge',
        name: 'balance_recharge',
        component: () => import('@/views/pay_trade/RechargeBalance'),
        meta: { title: '在线充值', menu: 'balance_statistics' }
      }
    ]
  },
  {
    path: '/commission',
    component: BasicLayout,
    meta: { menu: 'commission' },
    children: [
      {
        path: 'withdrawal',
        name: 'commission_withdrawal',
        component: () => import('@/views/commission_statistics/Withdrawal'),
        meta: { title: '提现申请', menu: 'commission_statistics' }
      }
    ]
  },
  {
    path: '/switch_user',
    name: 'switch_user',
    component: () => import('@/views/switch_user/index')
  },
  {
    path: '/pay_trades',
    component: BasicLayout,
    meta: { menu: 'finance' },
    children: [
      {
        path: ':id',
        name: 'pay_trade_info',
        component: () => import('@/views/pay_trade/Show'),
        meta: { title: '收银台' }
      }
    ]
  },
  {
    path: '/',
    component: CustomStyleLayout,
    meta: { menu: 'purchase' },
    children: [
      {
        path: 'goods',
        name: 'goods',
        component: () => import('@/views/agent_purchase_items/goods/index.vue'),
        meta: { title: '采购', menu: 'goods', permission: 'customer/goods' }
      }
    ]
  },
  {
    path: '/',
    component: CustomStyleLayout,
    meta: { menu: 'purchase' },
    children: [
      {
        path: 'cart',
        name: 'cart',
        component: () => import('@/views/agent_purchase_items/cart/index.vue'),
        meta: { title: '购物车', menu: 'cart', permission: 'customer/cart' }
      }
    ]
  },
  {
    path: '/cart',
    component: CustomStyleLayout,
    meta: { menu: 'purchase', title: '购物车' },
    children: [
      {
        path: 'settlement',
        name: 'cart_settlement',
        component: () => import('@/views/agent_purchase_items/cart/settlement/index.vue'),
        meta: { title: '确定订单', menu: 'cart', permission: 'customer/cart_settlement' }
      }
    ]
  },
  {
    path: '/goods',
    component: CustomStyleLayout,
    meta: { menu: 'purchase', title: '采购卡片' },
    children: [
      {
        path: 'purchase',
        name: 'new_agent_purchase_item',
        component: () => import('@/views/agent_purchase_items/New.vue'),
        meta: { title: '开始选购', menu: 'goods', permission: 'customer/agent_purchase_items/new' }
      }
    ]
  },
  {
    path: '/',
    component: CustomStyleLayout,
    meta: { menu: 'purchase' },
    children: [
      {
        path: 'agent_purchase_orders',
        name: 'agent_purchase_orders',
        component: () => import('@/views/agent_purchase_orders/index.vue'),
        meta: { title: '订单', menu: 'agent_purchase_orders', permission: 'customer/agent_purchase_orders' }
      }
    ]
  },
  {
    path: '/agencies',
    component: BasicLayout,
    meta: { title: '客户列表', menu: 'sales' },
    children: [
      {
        path: '/agencies',
        name: 'agencies',
        component: () => import('@/views/agencies/index'),
        meta: { menu: 'agencies', permission: 'customer/agencies' }
      },
      {
        path: ':id',
        component: () => import('@/views/agencies/ShowTab'),
        meta: { title: ':agency_name', menu: 'agencies' },
        children: [
          {
            path: 'info',
            name: 'agency_info',
            component: () => import('@/views/agencies/Info')
          },
          {
            path: 'sim_cards',
            name: 'agency_sim_cards',
            component: () => import('@/views/sim_cards/top_agent_view_agency/index')
          },
          {
            path: 'monthly_bills',
            name: 'agency_monthly_bills',
            component: () => import('@/views/agent_monthly_bills/top_agent_view_agency/index'),
            meta: { permission: 'customer/agent_monthly_bills/agency/by_top_agent' }
          },
          {
            path: 'monthly_bills/:monthly_bill_id',
            name: 'agency_monthly_bill_info',
            component: () => import('@/views/agent_monthly_bills/top_agent_view_agency/Show'),
            meta: { permission: 'customer/agent_monthly_bills/agency/by_top_agent' }
          },
          {
            path: 'allocation_orders/:allocation_order_id',
            name: 'agency_monthly_bill_allocation_order_info',
            component: () => import('@/views/agent_monthly_bills/allocation/top_agent_view_agency/show/index'),
            meta: { permission: 'customer/agent_monthly_bills/agency/by_top_agent' }
          },
          {
            path: 'card_use/:card_use_id',
            name: 'agency_monthly_bill_card_use_info',
            component: () => import('@/views/agent_monthly_bills/card_use/top_agent_view_agency/show/index'),
            meta: { permission: 'customer/agent_monthly_bills/agency/by_top_agent' }
          },
          {
            path: 'renew_orders/:renew_order_id',
            name: 'agency_monthly_bill_renew_order_info',
            component: () => import('@/views/agent_monthly_bills/renew/top_agent_view_agency/Show'),
            meta: { permission: 'customer/agent_monthly_bills/agency/by_top_agent' }
          },
          {
            path: 'active_orders/:active_order_id',
            name: 'agency_monthly_bill_active_order_info',
            component: () => import('@/views/agent_monthly_bills/active/top_agent_view_agency/Show'),
            meta: { permission: 'customer/agent_monthly_bills/agency/by_top_agent' }
          },
          {
            path: 'subscribe_orders/:subscribe_order_id',
            name: 'agency_monthly_bill_subscribe_order_info',
            component: () => import('@/views/agent_monthly_bills/subscribe/top_agent_view_agency/Show'),
            meta: { permission: 'customer/agent_monthly_bills/agency/by_top_agent' }
          },
          {
            path: 'stop/:stop_id',
            name: 'agency_monthly_bill_stop_info',
            component: () => import('@/views/agent_monthly_bills/stop/top_agent_view_agency/show/index'),
            meta: { permission: 'customer/agent_monthly_bills/agency/by_top_agent' }
          },
          {
            path: 'monthly_bills_commission',
            name: 'agency_monthly_bills_commission',
            component: () => import('@/views/agent_monthly_bills_commission/top_agent_view_agency/index'),
            meta: { permission: 'customer/agent_monthly_bills/agency/by_top_agent' }
          },
          {
            path: 'monthly_bills_commission/:monthly_bill_id',
            name: 'agency_monthly_bill_info_commission',
            component: () => import('@/views/agent_monthly_bills_commission/top_agent_view_agency/Show'),
            meta: { permission: 'customer/agent_monthly_bills/agency/by_top_agent' }
          },
          {
            path: 'renew_orders_commission/:renew_order_id',
            name: 'agency_monthly_bill_renew_order_info_commission',
            component: () => import('@/views/agent_monthly_bills_commission/renew/top_agent_view_agency/Show'),
            meta: { permission: 'customer/agent_monthly_bills/agency/by_top_agent' }
          },
          {
            path: 'active_orders_commission/:active_order_id',
            name: 'agency_monthly_bill_active_order_info_commission',
            component: () => import('@/views/agent_monthly_bills_commission/active/top_agent_view_agency/Show'),
            meta: { permission: 'customer/agent_monthly_bills/agency/by_top_agent' }
          },
          {
            path: 'subscribe_orders_commission/:subscribe_order_id',
            name: 'agency_monthly_bill_subscribe_order_info_commission',
            component: () => import('@/views/agent_monthly_bills_commission/subscribe/top_agent_view_agency/Show'),
            meta: { permission: 'customer/agent_monthly_bills/agency/by_top_agent' }
          },
          {
            path: 'transfers',
            name: 'agency_transfers',
            component: () => import('@/views/transfers/top_agent_view_agency/index'),
            meta: { permission: 'customer/transfers/agency/by_top_agent' }
          },
          {
            path: 'balance_statistics',
            name: 'agency_balance_statistics',
            component: () => import('@/views/balance_statistics/top_agent_view_agency/index'),
            meta: { permission: 'customer/balance_statistics/agency/by_top_agent' }
          },
          {
            path: 'withdrawals',
            name: 'agency_withdrawals',
            component: () => import('@/views/withdrawals/top_agent_view_agency/index'),
            meta: { permission: 'customer/withdrawals/agency/by_top_agent' }
          },
          {
            path: 'commission_statistics',
            name: 'agency_commission_statistics',
            component: () => import('@/views/commission_statistics/top_agent_view_agency/index'),
            meta: { permission: 'customer/commission_statistics/agency/by_top_agent' }
          },
          {
            path: 'products',
            name: 'agency_products',
            component: () => import('@/views/agents_products/top_agent_view_agency/index.vue'),
            meta: { menu: 'agencies', permission: 'customer/agents_products/agency/by_top_agent' }
          },
          {
            path: 'service_cycle_unexpired',
            name: 'agency_service_cycle_unexpired',
            component: () => import('@/views/sim_cards/top_agent_view_agency/service_cycle_manage/service_cycle_unexpired/index.vue'),
            meta: { permission: 'customer/sim_cards/service_cycle_manage/agency/by_top_agent' }
          },
          {
            path: 'service_cycle_expired',
            name: 'agency_service_cycle_expired',
            component: () => import('@/views/sim_cards/top_agent_view_agency/service_cycle_manage/service_cycle_expired/index'),
            meta: { permission: 'customer/sim_cards/service_cycle_manage/agency/by_top_agent' }
          },
          {
            path: 'current_month_expect_cancel',
            name: 'agency_current_month_expect_cancel',
            component: () => import('@/views/sim_cards/top_agent_view_agency/service_cycle_manage/current_month_expect_cancel/index'),
            meta: { permission: 'customer/sim_cards/service_cycle_manage/agency/by_top_agent' }
          },
          {
            path: 'renewed',
            name: 'agency_service_cycle_renewed',
            component: () => import('@/views/sim_cards/top_agent_view_agency/service_cycle_manage/renewed/index'),
            meta: { permission: 'customer/sim_cards/service_cycle_manage/agency/by_top_agent' }
          },
          {
            path: 'actived',
            name: 'agency_service_cycle_actived',
            component: () => import('@/views/sim_cards/top_agent_view_agency/service_cycle_manage/actived/index'),
            meta: { permission: 'customer/sim_cards/service_cycle_manage/agency/by_top_agent' }
          },
          {
            path: 'canceled',
            name: 'agency_service_cycle_canceled',
            component: () => import('@/views/sim_cards/top_agent_view_agency/service_cycle_manage/canceled/index'),
            meta: { permission: 'customer/sim_cards/service_cycle_manage/agency/by_top_agent' }
          },
          {
            path: 'traffic_pools',
            name: 'agency_traffic_pools',
            component: () => import('@/views/traffic_pools/top_agent_view_agency/index.vue'),
            meta: { menu: 'agencies', permission: 'customer/traffic_pools/agency/by_top_agent' }
          },
          {
            path: 'traffic_pools/:traffic_pool_id',
            component: () => import('@/views/traffic_pools/top_agent_view_agency/show/Tab'),
            meta: { permission: 'customer/traffic_pools/agency/by_top_agent/show' },
            children: [
              {
                path: 'info',
                name: 'agency_traffic_pool_info',
                component: () => import('@/views/traffic_pools/top_agent_view_agency/show/index'),
                meta: { permission: 'customer/traffic_pools/agency/by_top_agent/show' }
              },
              {
                path: 'sim_cards',
                name: 'agency_traffic_pool_sim_cards',
                component: () => import('@/views/traffic_pools/top_agent_view_agency/sim_card/index'),
                meta: { permission: 'customer/traffic_pools/agency/by_top_agent/show' }
              },
              {
                path: 'expand_records',
                name: 'agency_traffic_pool_expand_records',
                component: () => import('@/views/traffic_pools/top_agent_view_agency/expand_records/index'),
                meta: { permission: 'customer/traffic_pools/agency/by_top_agent/show' }
              },
              {
                path: 'usage_orders',
                name: 'agency_traffic_pool_usage_orders',
                component: () => import('@/views/traffic_pools/top_agent_view_agency/usage_orders/index'),
                meta: { permission: 'customer/traffic_pools/agency/by_top_agent/show' }
              }
            ]
          },
          {
            path: 'agency_api_info',
            name: 'agency_api_info',
            component: () => import('@/views/agencies/ApiInfo')
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    meta: { menu: 'agency_products' },
    children: [
      {
        path: 'products',
        name: 'agency_self_products',
        component: () => import('@/views/agents_products/agency_self/index.vue'),
        meta: { title: '套餐列表', menu: 'agency_products', permission: 'customer/agents_products/agency/by_self' }
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    meta: { menu: 'sales' },
    children: [
      {
        path: 'agents_products',
        name: 'agents_products',
        component: () => import('@/views/agents_products/index.vue'),
        meta: { title: '套餐列表', menu: 'agents_products', permission: 'customer/agents_products' }
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    meta: { menu: 'sales' },
    children: [
      {
        path: 'products',
        name: 'merchant_self_products',
        component: () => import('@/views/agents_products/agency_self/index.vue'),
        meta: { title: '套餐列表', menu: 'merchant_self_products', permission: 'customer/agents_products/agency/by_self' }
      }
    ]
  },
  {
    path: '/',
    component: CustomStyleLayout,
    meta: { menu: 'settings' },
    children: [
      {
        path: 'receive_addresses',
        name: 'receive_addresses',
        component: () => import('@/views/receive_addresses/index.vue'),
        meta: { title: '收货信息', menu: 'receive_addresses', permission: 'receive_addresses' }
      },
      {
        path: 'enterprise_qualifications',
        name: 'enterprise_qualifications',
        component: () => import('@/views/enterprise_qualifications/index.vue'),
        meta: { title: '企业信息', menu: 'enterprise_qualifications', permission: 'customer/enterprise_qualifications' }
      },
      {
        path: 'api_info',
        name: 'api_info',
        component: () => import('@/views/api/Info'),
        meta: { title: 'API 信息', menu: 'api_info', permission: 'agents/api_info' }
      },
      {
        path: 'customize_site',
        name: 'customize_site',
        component: () => import('@/views/customize_site/index.vue'),
        meta: { title: '站点配置', menu: 'customize_site', permission: 'customize_site' }
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    meta: { menu: 'cards' },
    children: [
      {
        path: 'sim_cards',
        name: 'sim_cards',
        component: () => import('@/views/sim_cards/index.vue'),
        meta: { title: 'SIM卡列表', menu: 'sim_cards', permission: 'customer/sim_cards' }
      },
      {
        path: 'pools',
        name: 'pools',
        component: () => import('@/views/traffic_pools/index.vue'),
        meta: { title: '流量池', menu: 'pools', permission: 'customer/traffic_pools' }
      },
      {
        path: '',
        component: () => import('@/views/sim_cards/service_cycle_manage/ShowTab.vue'),
        children: [
          {
            path: 'service_cycle_unexpired',
            name: 'service_cycle_unexpired',
            component: () => import('@/views/sim_cards/service_cycle_manage/service_cycle_unexpired/index.vue'),
            meta: { title: '服务周期', menu: 'service_cycle_manage', permission: 'customer/sim_cards/service_cycle_manage' }
          },
          {
            path: 'service_cycle_expired',
            name: 'service_cycle_expired',
            component: () => import('@/views/sim_cards/service_cycle_manage/service_cycle_expired/index'),
            meta: { title: '服务周期', menu: 'service_cycle_manage', permission: 'customer/sim_cards/service_cycle_manage' }
          },
          {
            path: 'current_month_expect_cancel',
            name: 'current_month_expect_cancel',
            component: () => import('@/views/sim_cards/service_cycle_manage/current_month_expect_cancel/index'),
            meta: { title: '本月预计销卡列表', menu: 'service_cycle_manage', permission: 'customer/sim_cards/service_cycle_manage' }
          },
          {
            path: 'renewed',
            name: 'service_cycle_renewed',
            component: () => import('@/views/sim_cards/service_cycle_manage/renewed/index'),
            meta: { title: '续期记录', menu: 'service_cycle_manage', permission: 'customer/sim_cards/service_cycle_manage' }
          },
          {
            path: 'actived',
            name: 'service_cycle_actived',
            component: () => import('@/views/sim_cards/service_cycle_manage/actived/index'),
            meta: { title: '续期记录', menu: 'service_cycle_manage', permission: 'customer/sim_cards/service_cycle_manage' }
          },
          {
            path: 'canceled',
            name: 'service_cycle_canceled',
            component: () => import('@/views/sim_cards/service_cycle_manage/canceled/index'),
            meta: { title: '销卡记录', menu: 'service_cycle_manage', permission: 'customer/sim_cards/service_cycle_manage' }
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    meta: { menu: 'oc_cards' },
    children: [
      {
        path: 'oc_sim_cards',
        name: 'oc_sim_cards',
        component: () => import('@/views/oc_sim_cards/index.vue'),
        meta: { title: '三方SIM卡列表', menu: 'oc_sim_cards', permission: 'customer/oc_sim_cards' }
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    meta: { menu: 'smses' },
    children: [
      {
        path: 'smses_overview',
        name: 'smses_overview',
        component: () => import('@/views/smses/Overview.vue'),
        meta: { title: '短信总览', menu: 'smses_overview', permission: 'customer/smses_overview' }
      },
      {
        path: 'sms_delivery_records',
        name: 'sms_delivery_records',
        component: () => import('@/views/smses/sms_delivery_records/index.vue'),
        meta: { title: '短信下发查询', menu: 'sms_delivery_records', permission: 'customer/smses' }
      },
      {
        path: 'sms_reception_records',
        name: 'sms_reception_records',
        component: () => import('@/views/smses/sms_reception_records/index.vue'),
        meta: { title: '短信上行查询', menu: 'sms_reception_records', permission: 'customer/smses' }
      },
      {
        path: 'sms_template_records',
        name: 'sms_template_records',
        component: () => import('@/views/smses/template_records/index.vue'),
        meta: { title: '短信模版管理', menu: 'sms_template_records', permission: 'customer/smses' }
      }
    ]
  },
  {
    path: '/pools',
    component: BasicLayout,
    meta: { title: '流量池', menu: 'cards' },
    children: [
      {
        path: ':id',
        component: () => import('@/views/traffic_pools/show/Tab.vue'),
        meta: { title: ':traffic_pool_name' },
        children: [
          {
            path: 'info',
            name: 'pool_info',
            component: () => import('@/views/traffic_pools/show/index.vue'),
            meta: { menu: 'pools', permission: 'customer/traffic_pools/show' }
          },
          {
            path: 'cards',
            name: 'pool_cards',
            component: () => import('@/views/traffic_pools/sim_card/index.vue'),
            meta: { menu: 'pools', permission: 'customer/traffic_pools/show' }
          },
          {
            path: 'expand_records',
            name: 'pool_expand_records',
            component: () => import('@/views/traffic_pools/expand_records/index.vue'),
            meta: { menu: 'pools', permission: 'customer/traffic_pools/show' }
          },
          {
            path: 'usage_orders',
            name: 'pool_usage_orders',
            component: () => import('@/views/traffic_pools/usage_orders/index'),
            meta: { menu: 'pools', permission: 'customer/traffic_pools/show' }
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    meta: { menu: 'finance' },
    children: [
      {
        path: 'finance_overview',
        name: 'finance_overview',
        component: () => import('@/views/finance/Overview.vue'),
        meta: { title: '财务总览', menu: 'finance_overview', permission: 'customer/finance_overview' }
      },
      {
        path: 'transfers',
        name: 'transfers',
        component: () => import('@/views/transfers/index'),
        meta: { title: '收款明细', menu: 'transfers', permission: 'customer/transfers' }
      },
      {
        path: 'balance_statistics',
        name: 'balance_statistics',
        component: () => import('@/views/balance_statistics/index'),
        meta: { title: '余额', menu: 'balance_statistics', permission: 'customer/balance_statistics' }
      },
      {
        path: 'monthly_bills',
        name: 'monthly_bills',
        component: () => import('@/views/agent_monthly_bills/index.vue'),
        meta: { title: '月账单', menu: 'monthly_bills', permission: 'customer/agent_monthly_bills' }
      },
      {
        path: 'allocation_orders/:id',
        name: 'allocation_orders',
        component: () => import('@/views/agent_monthly_bills/allocation/agency_self/show/index'),
        meta: { menu: 'monthly_bills', permission: 'customer/agent_monthly_bills' }
      },
      {
        path: 'card_uses/:card_use_id',
        name: 'monthly_bill_card_use_info',
        component: () => import('@/views/agent_monthly_bills/card_use/Show'),
        meta: { menu: 'monthly_bills', permission: 'customer/agent_monthly_bills' }
      },
      {
        path: 'agent_renew_orders/:renew_order_id',
        name: 'monthly_bill_renew_order_info',
        component: () => import('@/views/agent_monthly_bills/renew/show/index'),
        meta: { menu: 'monthly_bills', permission: 'customer/agent_monthly_bills' }
      },
      {
        path: 'agent_active_orders/:active_order_id',
        name: 'monthly_bill_active_order_info',
        component: () => import('@/views/agent_monthly_bills/active/show/index'),
        meta: { menu: 'monthly_bills', permission: 'customer/agent_monthly_bills' }
      },
      {
        path: 'agent_subscribe_orders/:subscribe_order_id',
        name: 'monthly_bill_subscribe_order_info',
        component: () => import('@/views/agent_monthly_bills/subscribe/show/index'),
        meta: { menu: 'monthly_bills', permission: 'customer/agent_monthly_bills' }
      },
      {
        path: 'stop/:stop_id',
        name: 'monthly_bill_stop_info',
        component: () => import('@/views/agent_monthly_bills/stop/Show'),
        meta: { menu: 'monthly_bills', permission: 'customer/agent_monthly_bills' }
      },
      {
        path: 'agent_consumption_bills',
        name: 'agent_consumption_bills',
        component: () => import('@/views/agent_consumption_bills/index.vue'),
        meta: { title: '账单明细', menu: 'agent_consumption_bills', permission: 'customer/agent_consumption_bills' }
      },
      {
        path: 'agent_consumption_bills/renew_orders/:renew_order_id',
        name: 'agent_consumption_bill_renew_order_info',
        component: () => import('@/views/agent_consumption_bills/renew/show/index'),
        meta: { menu: 'agent_consumption_bills', permission: 'customer/agent_consumption_bills' }
      },
      {
        path: 'agent_consumption_bills/active_orders/:active_order_id',
        name: 'agent_consumption_bill_active_order_info',
        component: () => import('@/views/agent_consumption_bills/active/show/index'),
        meta: { menu: 'agent_consumption_bills', permission: 'customer/agent_consumption_bills' }
      },
      {
        path: 'agent_consumption_bills/subscribe_orders/:subscribe_order_id',
        name: 'agent_consumption_bill_subscribe_order_info',
        component: () => import('@/views/agent_consumption_bills/subscribe/show/index'),
        meta: { menu: 'agent_consumption_bills', permission: 'customer/agent_consumption_bills' }
      }
    ]
  },
  {
    path: '/monthly_bills',
    component: BasicLayout,
    meta: { title: '月账单', menu: 'finance' },
    children: [
      {
        path: ':id',
        name: 'monthly_bill_info',
        component: () => import('@/views/agent_monthly_bills/Show'),
        meta: { title: ':month', menu: 'monthly_bills', permission: 'customer/agent_monthly_bills' }
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    meta: { menu: 'commission' },
    children: [
      {
        path: 'withdrawals',
        name: 'withdrawals',
        component: () => import('@/views/withdrawals/index'),
        meta: { title: '提现明细', menu: 'withdrawals', permission: 'customer/withdrawals' }
      },
      {
        path: 'commission_statistics',
        name: 'commission_statistics',
        component: () => import('@/views/commission_statistics/index'),
        meta: { title: '佣金', menu: 'commission_statistics', permission: 'customer/commission_statistics' }
      },
      {
        path: 'monthly_bills_commission',
        name: 'monthly_bills_commission',
        component: () => import('@/views/agent_monthly_bills_commission/index.vue'),
        meta: { title: '月订单', menu: 'monthly_bills_commission', permission: 'customer/agent_monthly_bills' }
      },
      {
        path: 'agent_renew_orders/:renew_order_id',
        name: 'monthly_bill_renew_order_info_commission',
        component: () => import('@/views/agent_monthly_bills_commission/renew/show/index'),
        meta: { menu: 'monthly_bills_commission', permission: 'customer/agent_monthly_bills' }
      },
      {
        path: 'agent_active_orders/:active_order_id',
        name: 'monthly_bill_active_order_info_commission',
        component: () => import('@/views/agent_monthly_bills_commission/active/show/index'),
        meta: { menu: 'monthly_bills_commission', permission: 'customer/agent_monthly_bills' }
      },
      {
        path: 'agent_subscribe_orders/:subscribe_order_id',
        name: 'monthly_bill_subscribe_order_info_commission',
        component: () => import('@/views/agent_monthly_bills_commission/subscribe/show/index'),
        meta: { menu: 'monthly_bills_commission', permission: 'customer/agent_monthly_bills' }
      },
      {
        path: 'agent_consumption_bills_commission',
        name: 'agent_consumption_bills_commission',
        component: () => import('@/views/agent_consumption_bills_commission/index.vue'),
        meta: { title: '订单明细', menu: 'agent_consumption_bills_commission', permission: 'customer/agent_consumption_bills' }
      },
      {
        path: 'agent_consumption_bills_commission/renew_orders/:renew_order_id',
        name: 'agent_consumption_bill_renew_order_info_commission',
        component: () => import('@/views/agent_consumption_bills_commission/renew/show/index'),
        meta: { menu: 'agent_consumption_bills_commission', permission: 'customer/agent_consumption_bills' }
      },
      {
        path: 'agent_consumption_bills_commission/active_orders/:active_order_id',
        name: 'agent_consumption_bill_active_order_info_commission',
        component: () => import('@/views/agent_consumption_bills_commission/active/show/index'),
        meta: { menu: 'agent_consumption_bills_commission', permission: 'customer/agent_consumption_bills' }
      },
      {
        path: 'agent_consumption_bills_commission/subscribe_orders/:subscribe_order_id',
        name: 'agent_consumption_bill_subscribe_order_info_commission',
        component: () => import('@/views/agent_consumption_bills_commission/subscribe/show/index'),
        meta: { menu: 'agent_consumption_bills_commission', permission: 'customer/agent_consumption_bills' }
      }
    ]
  },
  {
    path: '/monthly_bills_commission',
    component: BasicLayout,
    meta: { title: '月订单', menu: 'commission' },
    children: [
      {
        path: ':id',
        name: 'monthly_bill_info_commission',
        component: () => import('@/views/agent_monthly_bills_commission/Show'),
        meta: { title: ':month', menu: 'monthly_bills_commission', permission: 'customer/agent_monthly_bills' }
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    meta: { title: '通知消息', menu: '' },
    children: [
      {
        path: 'notifications',
        name: 'notifications',
        component: () => import('@/views/notifications/index.vue')
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    meta: { menu: 'queries' },
    children: [
      {
        path: 'batch_operation_records',
        name: 'batch_operation_records',
        component: () => import('@/views/batch_operation_records/index.vue'),
        meta: { title: '批量操作查询', menu: 'batch_operation_records', permission: 'batch_operation_records' }
      }
    ]
  },
  {
    path: '/',
    component: CustomStyleLayout,
    meta: { menu: 'queries' },
    children: [
      {
        path: 'batch_operation_records/:id/info',
        name: 'batch_operation_record_info',
        component: () => import('@/views/batch_operation_records/detail/index.vue'),
        meta: { title: '批量操作详情', menu: 'batch_operation_records', permission: 'batch_operation_records/show' }
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    meta: { menu: 'alarms' },
    children: [
      {
        path: 'alarm_setting',
        name: 'alarm_setting',
        component: () => import('@/views/agent_alarms/setting.vue'),
        meta: { title: '告警管理', menu: 'alarm_setting', permission: 'alarm_setting' }
      }
    ]
  },
  {
    path: '/registered_agents',
    component: RegisteredAgentLayout,
    children: [
      {
        path: 'home',
        name: 'registered_agent_home',
        component: RegisteredAgentHome,
        meta: { title: '首页', permission: 'customer/registered_agents/home' }
      },
      {
        path: 'enterprise_qualification/edit',
        name: 'edit_enterprise_qualification',
        component: () => import('@/views/registered_agent_home/enterprise_qualifications/Edit.vue'),
        meta: { title: '修改企业信息', permission: 'customer/enterprise_qualifications/edit' }
      },
      {
        path: ':id',
        name: 'registered_agent_user_info',
        component: () => import('@/views/registered_agent_home/registered_agent_user_info/index.vue'),
        meta: { title: '个人资料', permission: 'customer/users/edit_registered_agent_self_info' }
      }
    ]
  },
  {
    path: '/user',
    name: 'user',
    component: BasicLayout,
    children: [
      {
        path: ':id',
        name: 'user_info',
        component: () => import('@/views/users/personal_info/index.vue'),
        meta: { title: '个人资料', permission: 'customer/users/edit_self_info' }
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    meta: { title: '导出管理', menu: '' },
    children: [
      {
        path: 'data_export_tasks',
        name: 'data_export_tasks',
        component: () => import('@/views/data_export_tasks/index.vue')
      }
    ]
  }
]
