<template>
  <div class="logo-wrapper">
    <router-link to="/">
      <div class="logo-iot" v-if="$store.getters.isShowSiteLogo && $store.getters.userRole === 'top_agent'" style="background-color:#f8f8f8;">
        <div class="logo-icon-iot">
          <img
            :src="siteLogoUrl"
            alt="logo"
          >
        </div>
      </div>
      <div class="logo" v-else style="background-color:#f8f8f8">
        <div class="logo-words" style="color:#000;">
          物联卡管理平台
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import defaultLogo from '@/assets/images/logo.png'
export default {
  name: 'LeftTopLogo',
  computed: {
    siteLogoUrl() {
      return this.$store.getters.siteLogoUrl !== '' ? this.$store.getters.siteLogoUrl : defaultLogo
    }
  }
}
</script>

<style lang="less" scoped>
// logo 区域
.logo-wrapper {
  height: 64px;
  overflow: hidden;
  line-height: 64px;
  //border-right: 1px solid #e8e8e8;
  //border-bottom: 1px solid #e8e8e8;

  .logo {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;

    .logo-words {
      color: @primary-color;
      font-size: 24px;
      &:hover {
        color: @primary-color;
      }
    }
  }

  // logo
  .logo-iot {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    img {
      height: 55px;
    }
  }

  .logo-icon-iot img {
    width: 100%;
    padding: 5px;
  }
}

// 左侧菜单收缩时隐藏 logo 文字部分
.ant-layout-sider-collapsed {
  .logo-iot .logo-words-iot, .logo .logo-words {
    display: none;
  }
}
</style>

