import { login, qrcLogin, findUserInfo, logout } from '@/api/login'
import { clearStorage, getToken, setToken } from '@/utils/token'

const user = {
  state: {
    id: 0,
    fullName: '',
    role: '',
    agent_id: 0,
    agent_type: 0,
    registered_agent_id: 0,
    registered_agent_name: '',
    permissions: [],
    is_show_agreement: false,
    is_show_site_logo: true,
    site_logo_url: ''
  },

  mutations: {
    SET_IS_SHOW_AGREEMENT: (state, value) => {
      state.is_show_agreement = value
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_USER_INFO: (state, {
      id,
      fullName,
      role,
      agent_id,
      agent_type,
      registered_agent_id,
      registered_agent_name,
      is_show_site_logo,
      site_logo_url
    }) => {
      state.id = id
      state.fullName = fullName
      state.role = role
      state.agent_id = agent_id
      state.agent_type = agent_type
      state.registered_agent_id = registered_agent_id
      state.registered_agent_name = registered_agent_name
      state.is_show_site_logo = is_show_site_logo
      state.site_logo_url = site_logo_url
    },
    CLEAR_USER_INFO: (state) => {
      state.id = 0
      state.fullName = ''
      state.role = ''
      state.agent_id = 0
      state.agent_type = 0
      state.registered_agent_id = 0
      state.registered_agent_name = ''
      state.is_show_agreement = false
      state.is_show_site_logo = true
      state.site_logo_url = ''
    }
  },

  actions: {
    SetIsShowAgreement({ commit }, val) {
      return new Promise(resolve => {
        commit('SET_IS_SHOW_AGREEMENT', val)
        resolve()
      })
    },

    // 清除登录信息
    ClearLoginInfo({ commit }) {
      return new Promise(resolve => {
        commit('SET_PERMISSIONS', [])
        commit('CLEAR_USER_INFO', [])
        commit('CLEAR_NOTIFICATION_DATA')
        clearStorage()
        resolve()
      })
    },

    // 登录
    Login({ dispatch, commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(res => {
          if (res.code === 0) {
            dispatch('ClearLoginInfo')
            setToken(res.data.token)
            dispatch('SetIsNeedCheckAutoAlertNotification', true)
            resolve(res)
          } else {
            reject(res)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登录
    QrcLogin({ dispatch, commit }, userInfo) {
      return new Promise((resolve, reject) => {
        qrcLogin(userInfo).then(res => {
          if (res.code === 0) {
            dispatch('ClearLoginInfo')
            setToken(res.data.token)
            dispatch('SetIsNeedCheckAutoAlertNotification', true)
            resolve(res)
          } else {
            reject(res)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 注册成功后自动登录
    LoginAfterSuccessfulRegistration({ dispatch, commit }, token) {
      return new Promise((resolve, reject) => {
        dispatch('ClearLoginInfo')
        setToken(token)
        resolve()
      })
    },

    // 获取用户信息
    FindUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        findUserInfo().then(res => {
          if (res.code === 0) {
            const { data } = res
            commit('SET_PERMISSIONS', data.permissions)
            commit('SET_USER_INFO', {
              id: data.id,
              fullName: data.full_name,
              role: data.role,
              agent_id: data.agent_id,
              agent_type: data.agent_type,
              registered_agent_id: data.registered_agent_id,
              registered_agent_name: data.registered_agent_name,
              is_show_site_logo: data.is_show_site_logo,
              site_logo_url: data.site_logo_url
            })
            resolve(res)
          } else {
            reject(res)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout({ dispatch }) {
      return new Promise((resolve) => {
        logout(getToken()).then((res) => {
          if (res.code === 0) {
            dispatch('ClearLoginInfo')
            resolve(res)
          }
        }).catch((err) => {
          resolve(err)
        }).finally(() => {
        })
      })
    }
  }
}

export default user
