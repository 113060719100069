import request from '@/utils/request'

// 查询站点配置
export function getCustomizeSiteSetting(params) {
  return request({
    url: `customer/customize_site`,
    method: 'get',
    params: params
  })
}

// 更新站点配置
export function updateCustomizeSiteSetting(data) {
  return request({
    url: `customer/customize_site`,
    method: 'put',
    data
  })
}

