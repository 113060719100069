<template>
  <a-menu
    theme="dark"
    mode="inline"
    :open-keys="openedMenuKeys"
    v-model="currentMenu"
    class="side-menu-wrapper"
    @openChange="onOpenChange"
    style="background-color:rgb(4, 21, 40)"
  >

    <a-menu-item key="home">
      <router-link to="/">
        <a-icon type="home" />
        <span>首页</span>
      </router-link>
    </a-menu-item>

    <a-sub-menu key="cards">
      <span
        slot="title"
        class="submenu-title-wrapper"
      >
        <a-icon type="credit-card" />
        <span>SIM卡管理</span>
      </span>

      <a-menu-item key="sim_cards">
        <router-link :to="{ name: 'sim_cards' }">
          <span>SIM卡列表</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="pools">
        <router-link :to="{ name: 'pools' }">
          <span>流量池</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="service_cycle_manage">
        <router-link :to="{ name: 'service_cycle_unexpired' }">
          <span>服务周期</span>
        </router-link>
      </a-menu-item>
    </a-sub-menu>

    <a-menu-item key="agency_products">
      <router-link :to="{ name: 'agency_self_products' }">
        <a-icon type="profile" />
        <span>套餐列表</span>
      </router-link>
    </a-menu-item>

    <a-sub-menu key="finance">
      <span
        slot="title"
        class="submenu-title-wrapper"
      >
        <a-icon type="account-book" />
        <span>财务管理</span>
      </span>

      <a-menu-item key="monthly_bills">
        <router-link :to="{ name: 'monthly_bills' }">
          <span>月账单</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="transfers">
        <router-link :to="{ name: 'transfers' }">
          <span>收付款记录</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="balance_statistics">
        <router-link :to="{ name: 'balance_statistics' }">
          <span>余额</span>
        </router-link>
      </a-menu-item>
    </a-sub-menu>

    <a-sub-menu key="queries" v-if="isShowMenu('menu:customer/queries')">
      <span
        slot="title"
        class="submenu-title-wrapper"
      >
        <a-icon type="eye" />
        <span>系统查询</span>
      </span>

      <a-menu-item key="batch_operation_records">
        <router-link :to="{ name: 'batch_operation_records'}">
          <span>批量操作查询</span>
        </router-link>
      </a-menu-item>
    </a-sub-menu>

    <a-sub-menu key="settings" v-if="isShowMenu('menu:customer/queries')">
      <span
        slot="title"
        class="submenu-title-wrapper"
      >
        <a-icon type="setting" />
        <span>账户设置</span>
      </span>

      <a-menu-item key="api_info" v-if="isShowMenu('agents/api_info')">
        <router-link :to="{ name: 'api_info'}">
          <span>API 信息</span>
        </router-link>
      </a-menu-item>
    </a-sub-menu>
  </a-menu>
</template>

<script>
import { hasPermission } from '@/utils/permission'

export default {
  name: 'AgencySideMenu',
  data() {
    return {
      openedMenuKeys: this.$store.getters.currentMenus, // 当前打开的菜单keys(包含父子菜单)
      rootSubmenuKeys: ['home', 'cards', 'agency_products', 'finance', 'queries']
    }
  },
  computed: {
    // 当前选中的菜单
    currentMenu: {
      get() {
        return this.$store.getters.currentMenus
      },
      set(val) {
        // setActiveMenu 中设置
        // 这里给一个空的 setter, 解决在新标签打开菜单报错问题
      }
    }
  },
  watch: {
    $route: {
      handler: function() {
        this.openedMenuKeys = this.currentMenu
      }
    }
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openedMenuKeys.indexOf(key) === -1)
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openedMenuKeys = openKeys
      } else {
        this.openedMenuKeys = latestOpenKey ? [latestOpenKey] : []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.side-menu-wrapper {
  border-right-color: transparent;
  height: calc(100vh - 64px);
  overflow: auto;
  -ms-overflow-style: none;

  // 隐藏滚动条
  &::-webkit-scrollbar {
    display: none;
  }
}

.ant-menu-item {
    margin-top: 0px !important;
}
</style>
